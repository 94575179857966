.childCard {
	display: flex;
	flex-direction: column;
	margin-bottom: 48px;
}

.formContainer {
	border: 1px solid lightgray;
	padding: 48px;
	/* padding-bottom: 32px; */
	margin-top: 24px;
	border-radius: 4px;
}

/* .sectionHeader {
	font-size: 32px !important;
} */
