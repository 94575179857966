.reportFieldGroupContainer {
	margin-bottom: 24px;
}

.reportFieldGroup {
	background-color: #f3f3f3;
	padding: 12px 16px;
	border-radius: 8px;
	margin-bottom: 16px;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 600px;
}
