.tableHeaderContainer {
  width: 70%;
  display: flex;
  gap: 16px;
  font-size: 18px;
  margin-bottom: 48px;
  position: sticky;
  background-color: #fff;
  padding: 16px 0;
  left: 0;
  top: 0;
  border-bottom: 1px solid lightgray;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.tableHeader {
  flex: 1;
  font-weight: 600;
}

.tableContainer {
  max-height: 500px;
  overflow-y: auto;
  margin: 24px 0;
}

.table {
  width: 70%;
}

.tableRow {
  display: flex;
  gap: 16px;
  margin: 16px 0;
}

.tableCell {
  margin: 0 0 48px 0;
  /* flex: 1; */
}
