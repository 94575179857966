.deck {
  width: 100%;
  overflow-x: auto;
  display: flex;
  padding: 16px 0;
  margin-bottom: 24px;
}

.stageCard {
  min-width: max-content;
  /* padding: 0 24px; */
  height: 48px;
  border: 1px solid #384b70;
  color: #384b70;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-transform: uppercase; */

  /* border-radius: 8px; */
}
.stageCard:hover {
  background: #384b7010;
  color: #384b70;
  cursor: pointer;
}

.stationForm {
  min-width: max-content;
  padding: 0 16px;
  margin-top: -16px;
  margin-left: 24px;
  height: 48px;
  border: 1px solid #384b70;
  color: #384b70;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-transform: uppercase; */

  cursor: pointer;
  border-radius: 8px;
}

.stationForm:hover {
  background: #384b7010;
  color: #384b70;
  cursor: pointer;
}

.selected {
  background: #384b7020;
  color: #384b70;
  cursor: pointer;
}
