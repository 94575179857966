.reportPageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hide;
  position: relative;
}

.reportNav {
  height: 48px;
  display: flex;
  gap: 24px;
  overflow-x: auto;
  align-items: center;
  width: 100%;
  z-index: 4;
}

.reportNavInfo {
  display: flex;
  gap: 24px;
  display: flex;
  align-items: center;
}

.reportNavActions {
  height: 100%;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.reportHeader {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  border-bottom: 1px solid #384b7050;
  margin-bottom: 12px;
  height: auto;
  padding: 16px 0 24px 0;
  position: sticky;
  top: -24px;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* margin-bottom: 24px; */
  z-index: 200;
}

.reportHeaderTitle {
  display: flex;
  align-items: center;
  gap: 24px;
}

.reportSubheader {
  display: flex;
  gap: 24px;
  margin-bottom: 8px;
  padding-left: 4px;
}

.reportPage {
  height: auto;
  overflow-y: scroll;
}

.reportHeaderButtons {
  display: flex;
  gap: 24px;
}

.fieldWrapper {
  display: flex;
  gap: 16px;
  padding: 24px 16px;
  border: 1px solid lightgray;
  margin-bottom: 16px;
  border-radius: 4px;
  color: grey;
  max-width: 600px;
}

.fieldWrapper:hover .editIcon {
  scale: 1.25;
}

.fieldWrapper:hover {
  cursor: pointer;
  border: 1ps solid #fcfcfc;
  background-color: #fcfcfc;
  color: #333333;
}

.FiledContainerHeader {
  margin-bottom: 24px;
}

.fieldLabel {
  font-weight: 600;
}

.fieldValue {
  font-style: italic;
}

.emptyValue {
  color: #3333333d !important;
  font-style: italic;
  font-weight: 300;
  /* opacity: 50%/; */
}

.quickActions {
  max-width: 600px;
  margin: 24px 0;
}

.carousal {
  display: flex;
  gap: 24px;
  overflow-x: scroll;
}

.imageContainer {
  min-width: 200px;
  max-width: 200px;
  height: 100%;
  margin: 16px 0;
  padding: 16px;
  border: 1px solid #d3d3d380;
  border-radius: 8px;
}

.carousalImage {
  display: block;
  width: 100%;
  border-radius: 8px;
}

/* table */

.tableHeaderContainer {
  width: 70%;
  display: flex;
  gap: 16px;
  font-size: 18px;
  margin-bottom: 48px;
  position: sticky;
  background-color: #fff;
  padding: 16px 0;
  left: 0;
  top: 0;
  border-bottom: 1px solid lightgray;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.tableHeader {
  flex: 1;
  font-weight: 600;
}

.tableContainer {
  max-height: 500px;
  overflow-y: auto;
  margin: 24px 0;
}

.table {
  width: 70%;
}

.tableGroupHeader {
  font-size: 18px;
  color: #5f5f5f;
  margin-top: 32px;
}

.tableRow {
  display: flex;
  gap: 16px;
  margin: 16px 0;
}

.tableCell {
  margin: 0 0 48px 0;
  border-left: 1px solid gray;
  padding: 8px 16px 16px;
  cursor: pointer;
  /* flex: 1; */
}

.tableCell:hover {
  background-color: #e3e3e378;
}

.tableCellActive {
  background-color: #e3e3e378;
}

.twoColumnTable {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 70%;
}

.notesContainer {
  max-height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.note {
  border: 1px solid lightgray;
  padding: 16px;
  border-radius: 4px;
}
