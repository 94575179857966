.dashboardContainer {
}

.dashboardHeader {
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
}

.qrCodeImage {
	margin-bottom: 32px;
	padding: 16px;
	border: 1px solid lightgray;
	border-radius: 8px;
}

.dashboardButtonContainer {
	display: flex;
	gap: 24px;
}
