.mainArea {
  height: calc(100dvh - 64px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 24px;
  gap: 24px;
  width: 100%;
}

.childContainer {
  order: 2;
}
.outletContainer {
  order: 1;
  border-bottom: 1px solid lightgray;
  padding-bottom: 32px;
  position: relative;
}

.childContainer,
.outletContainer {
  /* padding: 24px; */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}
.childContainer::-webkit-scrollbar,
.outletContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.highlightOffIconContainer {
  position: absolute;
  right: 0;
  /* top: 36px; */
  cursor: pointer;
}

@media only screen and (min-width: 1025px) {
  .mainArea {
    margin-left: 270px;
    flex-direction: row;
    padding: 0 32px;
    gap: 32px;
  }

  .childContainer,
  .outletContainer {
    flex: 1;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 0;
    padding: 24px 0;
  }

  .childContainer {
    order: 1;
  }
  .outletContainer {
    order: 2;
    border: none;
    border-left: 1px solid lightgray;
    padding-left: 32px;
  }
}
